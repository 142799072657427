import Image from "next/image";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { $stylesReset, IStyles, Hr, TheIcon, TheIconLink } from "components";
import { WatchGamesTVLinks } from "helpers/constants";

export interface IFooterComponent {
  id?: string;
}

export const FooterComponent: React.FC<IFooterComponent & IStyles> = ({
  id,
  styles,
}): React.ReactElement => {
  return (
    <Box id={id} styles={styles}>
      <Content>
        <FooterRow>
          <FooterLogo>
            <Image width={186} height={36} src="/images/logos/logo.svg" alt="logo" />
            <TextCopyright>Copyright@2024 WRewards</TextCopyright>
          </FooterLogo>
          <FooterMenus>
            <FooterMenu>
              <TextTitle>Explore</TextTitle>
              <TextMenu>
                <Link href="/leaderboards">Leaderboard</Link>
              </TextMenu>
              <TextMenu>
                <Link href="/points-shop">Points Shop</Link>
              </TextMenu>
              <TextMenu>
                <Link href="/pachinko">Pachinko</Link>
              </TextMenu>
              <TextMenu>
                <Link href="/raffles">Raffles</Link>
              </TextMenu>
            </FooterMenu>
            <FooterMenu>
              <TextTitle>About</TextTitle>
              <TextMenu>
                <Link href="/contact-us">Contact</Link>
              </TextMenu>
              {/* <TextMenu>
                <Link href="#">Gambling Help</Link>
              </TextMenu> */}
              <TextMenu>
                <Link href="/faq">FAQ</Link>
              </TextMenu>
              <TextMenu>
                <Link href="/terms-of-service">Terms of service</Link>
              </TextMenu>
              <TextMenu>
                <Link href="/privacy-policy">Privacy Policy</Link>
              </TextMenu>
            </FooterMenu>
          </FooterMenus>
          <FooterStay>
            <TextTitle>Stay up to date</TextTitle>
            <Links>
              <TheIconLink target="_blank" href={WatchGamesTVLinks.discord}>
                <TheIcon icon="discord:social" />
              </TheIconLink>
              <TheIconLink target="_blank" href={WatchGamesTVLinks.youtube}>
                <TheIcon icon="youtube:social" />
              </TheIconLink>
              <TheIconLink target="_blank" href={WatchGamesTVLinks.twitter}>
                <TheIcon icon="twitter:social" />
              </TheIconLink>
            </Links>
          </FooterStay>
        </FooterRow>
        <FooterHr />
        <FooterItem18>
          <Text18>
            18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do
            not think of gambling as a way to make money. Only gamble with money you can afford to
            lose. Set a money and time limit in advance. Never chase your losses. Don&#39;t use
            gambling to distract yourself from everyday problems.
          </Text18>
          <Badge>
            <BadgeText>made by</BadgeText>
            <Link href={"https://www.evo-verse.com/igaming"} target="_blank">
              <Image src="/images/icons/evoverse.svg" alt="evoverse" width={178} height={38} />
            </Link>
          </Badge>
        </FooterItem18>
      </Content>
    </Box>
  );
};

const Box = styled.footer<IStyles>`
  ${$stylesReset}
  padding: 32px 24px;
  background-color: var(--global-bg-dark-1);
  border-top: 1px solid rgba(210, 234, 255, 0.05);
  max-width: 100vw;
  width: 100%;
  padding-top: var(--header-height);

  @media (max-width: 500px) {
    padding-top: var(--mobile-header-height);
  }

  ${({ styles }) => styles}
`;

const Content = styled.div`
  ${$stylesReset}
  max-width: 1280px;
  margin: 0 auto;
`;

const FooterItem = styled.div`
  ${$stylesReset}
`;

const FooterItems = styled(FooterItem)`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 86px;
`;

const FooterRow = styled(FooterItems)`
  @media (max-width: 1024px) {
    gap: 48px;
  }
  @media ((min-width: 480px) and (max-width: 791px)) {
    gap: 48px;
    & > :nth-child(2) {
      order: 3;
    }
    & > :nth-child(3) {
      order: 2;
      flex-grow: 1;
    }
  }
`;

const FooterMenus = styled(FooterItems)`
  flex-grow: 1;
  @media (max-width: 1024px) {
    gap: 40px;
  }
`;

const FooterLogo = styled(FooterItem)`
  display: inline-flex;
  flex-flow: column;
  gap: 24px;
  margin-right: 55px;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
`;

const FooterStay = styled(FooterItem)`
  display: inline-flex;
  flex-flow: column;
  gap: 24px;
`;

const FooterMenu = styled(FooterItem)`
  display: inline-flex;
  flex-flow: column;
  gap: 16px;
`;

const FooterHr = styled(Hr)`
  margin: 32px 0;
`;

const Links = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

const Text = styled.span`
  --color: rgba(210, 234, 255, 0.25);
  color: var(--color);
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: left;
`;

const TextCopyright = styled(Text)`
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
`;

const Text18 = styled(Text)`
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
`;

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BadgeText = styled.div`
  display: flex;
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

const FooterItem18 = styled(FooterItem)`
  width: 100%;
  overflow: hidden;
  display: flex;
  gap: 64px;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  & ${Text18} {
    max-width: 785px;
  }
`;

const TextMenu = styled(Text)`
  --color-active: rgb(210, 234, 255); // #D2EAFF
  font-size: 16px;
  text-transform: uppercase;
  &:hover {
    color: var(--color-active);
  }
`;

const TextTitle = styled(TextMenu)`
  color: var(--color-active);
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
`;
