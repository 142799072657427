import numeral from "numeral";
import * as luxon from "luxon";
import React from "react";
import styled, { css } from "styled-components";

import { IStyles } from "components";
import { ILeaderboardWithWinnerDto } from "interfaces";
import { useRouter } from "next/router";
import { formatNumber } from "./utils/formatNumbers";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import { Avatar } from "@chakra-ui/react";
import { DEFAULT_AVATAR } from "helpers/constants";
import Gift from "/public/images/icons/gift.svg";
export interface ILeaderboardHistoryCard {
  leaderboard?: ILeaderboardWithWinnerDto;
  doHref?: (leaderboard?: ILeaderboardWithWinnerDto) => string;
}

export const LeaderboardHistoryCard: React.FC<ILeaderboardHistoryCard> = ({
  leaderboard,
  doHref = (leaderboard?: ILeaderboardWithWinnerDto) => `/leaderboards/${leaderboard?.id ?? "xxx"}`,
}): React.ReactElement => {
  const router = useRouter();
  const value = formatNumber(leaderboard?.totalPrizePool ? leaderboard?.totalPrizePool : 0);
  const endDate = leaderboard?.endDate
    ? luxon.DateTime.fromISO(leaderboard?.endDate).toFormat("dd MMM yyyy")
    : "?";
  const startDate = leaderboard?.startDate
    ? luxon.DateTime.fromISO(leaderboard?.startDate).toFormat("dd MMM yyyy")
    : "?";
  const href = doHref(leaderboard); // `/leaderboards/${ leaderboard?.id ?? 'xxx' }`;

  return (
    <Box>
      <AnimatedBackground />
      <BoxContent>
        <Header>
          <span className="name">Leaderboard</span>
          <span className="id">#{leaderboard?.id?.slice(0, 4) ?? "00"}</span>
        </Header>

        <Winner>
          <div className="place">1ST Place</div>
          <div className="avatarWrapper">
            <Avatar src={leaderboard?.winner?.avatar ?? DEFAULT_AVATAR} />
            <span className="winnerName">{leaderboard?.winner?.displayName ?? "UNKNOWN"}</span>
          </div>
        </Winner>
        <Footer>
          <div className="labels">
            <FooterItemTitle className="start item">started</FooterItemTitle>
            <FooterItemTitle className="end item">finished</FooterItemTitle>
          </div>
          <div className="values">
            <div className="start item">{startDate}</div>
            <div className="end item">{endDate}</div>
          </div>
        </Footer>

        <PrizeWrapper className="prize">
          <FooterItemTitle>Total Pool</FooterItemTitle>
          <Prize>
            <Reward>
              <Gift/>
            </Reward>
            <span>{`$ ${value}`}</span>
          </Prize>
        </PrizeWrapper>

        <WrewardsButton onClick={() => router.push(href)} className="show-button">
          Show
        </WrewardsButton>
      </BoxContent>
    </Box>
  );
};

const Reward = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #131620;
  color: #C8D7F5BF;
  border: 2px solid #efc243;
  border-radius: 60px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const Box = styled.div`
  box-sizing: border-box;
  /* width: 315px;
  height: 244px; */
  position: relative;
  border-radius: 0.5rem;
  cursor: pointer;
`;

const Winner = styled.div`
  box-sizing: border-box;
  /* width: 315px;
  height: 244px; */
  position: relative;
  border-radius: 0.5rem;
  .place {
    color: var(--daylight-daylight-half, rgba(200, 215, 245, 0.5));
    font-family: var(--font-family-golos);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .winnerName {
    text-transform: uppercase;
    font-family: var(--font-family-exo);
    font-size: 20px;
    font-style: italic;
    font-weight: 800;
    color: var(--daylight-daylight-main, #d5e0f5);
  }
`;

const AnimatedBackground = styled.div<IStyles & { place?: number }>`
  /* --image: url("/images/leaderboard/LeaderboardWinnerCard/Vector_v2.svg");
  --gradient: linear-gradient(180deg, rgba(57, 57, 80, 0.75) 0%, rgba(24, 28, 44, 0.75) 140.57%); */

  position: absolute;
  inset: 0;
  z-index: 0;
  display: flex;

  border-radius: inherit;
  overflow: hidden;
  /* background-image: var(--gradient); */

  /* &::before,
  &::after {
    content: "";
    position: absolute;
    inset: -100px;
    display: block;
    z-index: 0;
    width: 600px;
    height: 600px;
    border-radius: inherit;
    background-size: contain;
    background-image: var(--image);
    opacity: 0.1;
  } */

  /* &::after {
    transform-origin: center top;
    animation: diagonalMove1 30s linear infinite;
  } */
  /* fix rotation */
  /* &::before {
    margin-top: 3rem;
    transform-origin: center right;
    animation: diagonalMove2 30s linear infinite;
  } */
`;

const Header = styled.h6`
  color: rgba(210, 234, 255, 0.75);
  margin: 0;
  font-family: var(--font-family-exo);
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 57px;
  border: 1px solid var(--clouds-35, rgba(171, 186, 219, 0.35));
  background: var(--clouds-35, rgba(171, 186, 219, 0.35));
  /* overflow: hidden; */
  /* &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background: rgba(210, 234, 255, 0.05);
    z-index: 0;
  } */

  .name {
    flex: 1;
    padding: 8px;
    border-radius: 57px 0 0 57px;
    background: var(--Dark-Midnight, #141722);
  }

  .id {
    padding: 8px;
    /* background: var(--clouds-35, rgba(171, 186, 219, 0.35)); */
    display: inline-flex;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const PrizeWrapper = styled.div`
  color: var(--daylight-daylight-half, rgba(200, 215, 245, 0.5));
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Prize = styled.span`
  color: #efc243;
  width: 100%;
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  text-align: center;
  padding: 1rem;
  width: 100%;
  background: var(--clouds-5, rgba(171, 186, 219, 0.05));
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Footer = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.75);
  /* position: relative; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div {
    width: 50%;
    /* padding: 1.125rem; */
    position: relative;
    /* height: 60px; */
  }

  .item {
    flex: 1;
  }

  .labels {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .values {
    /* position: relative; */
    width: 100%;
    border-radius: 57px;
    border: 1px solid var(--Dark-Rainy-Morning, #2a3046);
    background: var(--Dark-Midnight, #141722);
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    .item {
      padding: 8px;
    }

    .item:first-child {
      border-right: 1px solid var(--Dark-Rainy-Morning, #2a3046);
    }
    /* &::before {
      content: "";
      position: absolute;
      top: 50%;
      width: calc(100% + 32px);
      margin: 0 -32px;
      border-top: 1px solid rgba(210, 234, 255, 0.05);
      z-index: -1;
    } */
  }

  /* & > div:first-child {
    border-right: 1px solid rgba(210, 234, 255, 0.05);
  } */
`;

const BoxContent = styled.div<IStyles>`
  /* padding-top: 1.5rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  border-radius: 12px;
  border: 2px solid var(--clouds-35, rgba(171, 186, 219, 0.35));
  background: linear-gradient(180deg, #202539 0%, #181e30 100%);
  backdrop-filter: blur(10px);
  min-height: 320px;
  z-index: 10;
  &::before {
    transition: all 0.2s ease-in-out;

    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
  }

  &:hover {
    &::before {
      background: var(--daylight-daylight-10, rgba(200, 215, 245, 0.1));
    }

    ${Footer} {
      .start {
        display: initial;
        opacity: 1;
      }
    }

    ${Winner} {
      display: initial;
    }

    .prize {
      display: none;
    }

    .show-button {
      display: initial;
    }
  }

  .start {
    display: none;
    opacity: 0;
    transition: 0.3s ease-in-out;
    /* opacity: 0; */
  }

  .show-button {
    display: none;
  }

  ${Winner} {
    display: none;
  }
`;

const FooterItemTitle = styled.div<IStyles>`
  /* position: absolute; */
  /* top: 0; */
  /* left: 50%; */
  text-transform: uppercase;
  font-size: 0.75rem;
  /* transform: translate(-50%, -50%); */
`;
