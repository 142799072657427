import React from "react";
import styled from "styled-components";
import { Flex } from "components/common";
import Link from "next/link";
import { TheLeaderboardSourceKeys } from "interfaces";
import LeaderboardHowToPlay from "./LeaderboardHowToPlay";

interface LeaderboardDisclaimerProps {
  casinoProvider: string;
}

const Container = styled.div`
  max-width: 1008px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.5rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-golos);

  h6 {
    font-style: italic;
    font-weight: 600;
    color: #3b8be9;
    font-size: 20px;
    margin: 0 0 2.25rem;
  }

  p {
    max-width: 700px;
    margin: 1.5rem auto;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    text-align: center;
    color: #d2eaff;
  }

  a {
    text-transform: uppercase;
    color: #5ebd3d;
    font-size: 18px;
    font-weight: 500;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const LeaderboardDisclaimer: React.FC<LeaderboardDisclaimerProps> = ({ casinoProvider }) => {
  switch (casinoProvider) {
    case TheLeaderboardSourceKeys.hypedrop:
      return (
        <Container>
          <Content>
            <h6>CLOSURE NOTICE!</h6>
            <p>
              The HypeDrop team has decided to close the website operations for the foreseeable
              future. Kindly withdraw your account balance or order any items in your cart by 26th
              April 2024.
            </p>
            <a href="https://blog.hypedrop.com/closure-notice/" target="_blank">
              Read More
            </a>
          </Content>
        </Container>
      );

    default:
      return <LeaderboardHowToPlay casinoProvider={casinoProvider} />;
  }
};

export default LeaderboardDisclaimer;
