import RaffleRouletteSnackbar, { RaffleDataProps } from "helpers/raffleLinkSnackbar";
import Timer, { EndedRaffleData } from "helpers/timer";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive";

function Notification() {
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });

  return (
    <ToastContainer
      containerId="toast"
      toastClassName="toastClassName"
      style={isMobile ? { marginTop: 140 } : { marginTop: 80 }}
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  );
}

const triggerNotification = ({
  text,
  type,
  timerData,
  raffleData,
}: {
  text?: string;
  type: string;
  timerData?: EndedRaffleData;
  raffleData?: RaffleDataProps;
}) => {
  switch (type) {
    case "error":
      toast.error(`${text}`);
      break;
    case "info":
      toast.info(`${text}`);
      break;
    case "success":
      toast.success(`${text}`);
      break;
    case "warning":
      toast.warning(`${text}`);
      break;
    case "timer":
      toast.info("toast", {
        toastId: "timerId",
      });
      toast.update("timerId", {
        render: () => (timerData ? <Timer data={timerData} /> : null),
      });
      break;
    case "raffleRoulette":
      toast.info("toast", {
        toastId: "raffleRoulette",
      });
      toast.update("raffleRoulette", {
        render: () => (raffleData ? <RaffleRouletteSnackbar raffle={raffleData} /> : null),
      });
      break;
    case "default":
      toast(`${text}`);
      break;
    default:
      break;
  }
};

export { Notification, triggerNotification };
