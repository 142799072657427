import React from "react";
import { DateTime } from "luxon";
import styled, { css } from "styled-components";
import { TheButton, TheDatePicker, TheFieldset, IStyles } from "components";
import { useAppContext } from "contexts";
import { IDaoLeaderboard, TheLeaderboardSourceKeys } from "interfaces";
import Image from "next/image";
import { Flex } from "components/common";
import { triggerNotification } from "components/TheComponents/Notification";
import ChakraModal from "components/TheComponents/Modal";
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { SlideSwitcher } from "components";
import LeaderboardPrizesModalLayout from "./LeaderboardPrizesModalLayout";
import VerifierProvider, { useVerifierContext } from "contexts/VerifierProvider";
import useLeaderboardWithWinners from "hooks/leaderboards/useLeaderboardsWithWinners";
import LeaderboardProviderSwitcher from "components/leaderboard/LeaderboardProviderSwitcher";

export interface IDaoLeaderboardWithEndDate extends IDaoLeaderboard {
  endDate: string;
}

export interface ILeaderboardCreateProps {
  switcher?: string;
  onCreate: (item: IDaoLeaderboard) => void;
  onClose: () => void;
}

const LeaderboardCreate: React.FC<ILeaderboardCreateProps> = ({
  switcher: switcherInit,
  onCreate,
}): React.ReactElement => {
  const { accessFetch } = useAppContext();
  const { verify } = useVerifierContext();

  const { isOpen: isPrizesOpen, onOpen: onOpenPrizes, onClose: onClosePrizes } = useDisclosure();

  const [payload, setPayload] = React.useState<IDaoLeaderboard>({
    casino: switcherInit ?? TheLeaderboardSourceKeys.gamdom,
    startDate: DateTime.utc().startOf("day").toISO() as string,
    duration: "1",
    prize1: "",
    prize2: "",
    prize3: "",
    randomPrizeThreshold: "0",
    maxPublicUsersCount: "10",
    additionalPrizes: [],
    randomPrizes: [],
  });
  // const [showPrizesModal, setShowPrizesModal] = React.useState<boolean>(false);

  const numProps = { pattern: "^[d]*$", type: "text" };

  const endDate: string | null = React.useMemo(() => {
    const { startDate, duration } = payload;
    const date = startDate ? DateTime.fromISO(startDate) : null;
    const days = duration !== "" && Number.isInteger(+duration) ? +duration : 0;
    if (date?.isValid && days > 0) {
      const hours = days * 24;
      const endDate = date.plus({ hours }).minus({ seconds: 1 }).toUTC();
      return endDate.toISO();
    }
    return null;
  }, [payload]);

  const onDateChage = (key: string) => (value: string | null) => {
    if (!value) return;
    const date = DateTime.fromISO(value).setZone("UTC").startOf("day").toISO();
    setPayload((payload) => ({ ...payload, [key]: date }));
  };

  const onTextChange = (key: string) => (event: React.ChangeEvent) => {
    event.preventDefault();
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) return;
    const { value } = target as HTMLInputElement;
    setPayload((payload) => ({ ...payload, [key]: value }));
  };

  const doSave = async ({ otp }: { otp: string }) => {
    try {
      const response = await accessFetch("/leaderboard/new", {
        method: "POST",
        headers: {
          "x-otp": otp,
        },
        body: JSON.stringify({
          casinoProvider: payload.casino,
          startDate: payload.startDate,
          durationDays: +payload.duration,
          maxPublicUsersCount: +payload.maxPublicUsersCount,
          prize1: +payload.prize1,
          prize2: +payload.prize2,
          prize3: +payload.prize3,
          randomPrizeThreshold: +payload.randomPrizeThreshold,
          additionalPrizes: payload.additionalPrizes.map((el) => ({
            prizeNumber: el.prizeNumber,
            amount: +el.amount,
          })),
          randomPrizes: payload.randomPrizes.map((el) => ({
            prizeNumber: el.prizeNumber,
            amount: +el.amount,
          })),
        }),
      });
      const json = await response?.json();
      if (json.error && json.message && json.statusCode) {
        triggerNotification({ text: json.message, type: "error" });
      } else {
        onCreate && onCreate(payload);
        triggerNotification({ text: "Leaderboard created", type: "success" });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const onSaveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const fieldsToValidate = [payload.prize1, payload.prize2, payload.prize3, payload.duration];
    const valid = fieldsToValidate.every(
      (field) => field !== "" && Number.isInteger(+field) && +field >= 0
    );

    const maxPublicUsersCount = payload?.maxPublicUsersCount;
    const isMaxUsersValid =
      maxPublicUsersCount &&
      maxPublicUsersCount !== "" &&
      Number.isInteger(+maxPublicUsersCount) &&
      +maxPublicUsersCount >= 10;

    if (valid && isMaxUsersValid) {
      return verify(({ otp }) => doSave({ otp }));
    } else {
      triggerNotification({ text: "Validation failed", type: "error" });
    }
  };

  return (
    <Box>
      <BoxItem
        styles={css`
          margin-bottom: 8px;
          margin: auto;
        `}
      >
        <LeaderboardProviderSwitcher
          value={payload?.casino}
          onChange={(value: string) => setPayload({ ...payload, casino: value })}
        />
        {/* <SlideSwitcher
          value={payload?.casino}
          centerSwitcher
          onChange={(value: string) => setPayload({ ...payload, casino: value })}
          isGridView={true}
        /> */}
      </BoxItem>
      <BoxItem>
        <TheFieldset title="Start date">
          <TheDatePicker value={payload?.startDate ?? null} onChange={onDateChage("startDate")} />
        </TheFieldset>
      </BoxItem>
      <BoxItem>
        <TheFieldset title="Duration (days)">
          <input
            {...numProps}
            placeholder="Duration (days)"
            value={payload?.duration ?? ""}
            onChange={onTextChange("duration")}
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem>
        <TheFieldset title="End date">
          <TheDatePicker value={endDate} readOnly />
        </TheFieldset>
      </BoxItem>
      <BoxItem>
        <TheFieldset title="Max Public Users">
          <input
            {...numProps}
            placeholder="Max Public Users"
            value={payload?.maxPublicUsersCount ?? ""}
            onChange={onTextChange("maxPublicUsersCount")}
          />
        </TheFieldset>
      </BoxItem>
      <Flex
        style={{
          border: "1px solid rgba(36, 39, 70, 0.5)",
          borderRadius: 8,
          cursor: "pointer",
        }}
        width="100%"
        background="rgba(36, 39, 70, 0.5)"
        height={37}
        align="center"
        onClick={onOpenPrizes}
        justify="center"
      >
        <Image
          style={{ marginRight: 8 }}
          src="/images/icons/add-icon.png"
          alt="plus"
          width={15}
          height={15}
        />
        <ManagePrizesButton>Add Prizes</ManagePrizesButton>
      </Flex>
      <BoxItem
        styles={css`
          margin-top: 8px;
        `}
      >
        <PreFillLeaderboardMenu
          casinoProvider={payload?.casino}
          setPrefill={(data) => setPayload((prev) => ({ ...prev, ...data }))}
        />
      </BoxItem>
      <BoxItem
        styles={css`
          margin-top: 8px;
        `}
      >
        <TheButton preset="blue" width={"100%"} onClick={onSaveClick}>
          Save item
        </TheButton>
      </BoxItem>
      <ChakraModal
        isOpen={isPrizesOpen}
        onClose={onClosePrizes}
        styles={css`
          max-height: 700px;
        `}
        size="2xl"
        title="ADD PRIZES"
        content={
          <LeaderboardPrizesModalLayout
            setPayload={setPayload}
            payload={payload}
            onClose={onClosePrizes}
          />
        }
      />
    </Box>
  );
};

const PreFillLeaderboardMenu: React.FC<{
  casinoProvider: string;
  setPrefill: (obj: Partial<IDaoLeaderboard>) => void;
}> = ({ casinoProvider, setPrefill }) => {
  const {
    data: { items },
    isFetching,
  } = useLeaderboardWithWinners({ casinoProvider, take: 10 });
  // console.log(items);

  const mapped = items.map((leaderBoard) => ({
    title: DateTime.fromISO(leaderBoard.endDate).toFormat("yyyy LLLL"),
    value: {
      randomPrizeThreshold: leaderBoard.randomPrizeThreshold.toString(),
      randomPrizes: leaderBoard.randomPrizes,
      prize1: leaderBoard.prize1.toString(),
      prize2: leaderBoard.prize2.toString(),
      prize3: leaderBoard.prize3.toString(),
      additionalPrizes: leaderBoard.additionalPrizes,
      maxPublicUsersCount: leaderBoard.maxPublicUsersCount.toString(),
    },
  }));

  return (
    <Menu placement={"bottom"} isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton isActive={isOpen} as={MenuBtn}>
            <span>Prefill prizes form Last Leaderboard</span>
            <div className={"icon"}>
              <Image alt="g-coin" src="/images/icons/arrow-down.svg" width={10} height={10} />
            </div>
          </MenuButton>
          <MenuList
            padding={0}
            background={"transparent"}
            width={"max-content"}
            overflow={"hidden"}
            borderRadius={"8px"}
          >
            <MenuListStyled>
              {mapped.map(({ value, title }) => {
                return (
                  <MenuItemOption
                    key={`${value.randomPrizeThreshold}-${title}`}
                    onClick={() => setPrefill(value)}
                    as={MenuItemBtn}
                    background={"transparent"}
                  >
                    {title}
                  </MenuItemOption>
                );
              })}
              {isFetching ? (
                <div className="spinner">
                  <Spinner />{" "}
                </div>
              ) : null}
            </MenuListStyled>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export const MenuBtn = styled.button`
  width: 100%;
  border: none;
  border-radius: 8px;
  min-width: max-content;
  background: #24274680 !important;
  display: flex;
  align-items: center;
  font-family: var(--font-family-golos), sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #d2eaff80;
  padding: 10px 16px;
  line-height: normal;
  cursor: pointer;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  &[aria-expanded="true"] img,
  &[aria-expanded="true"] svg {
    transform: rotate(180deg);
  }

  img,
  svg {
    transition: 0.3s transform ease-in-out;
  }

  .icon {
    width: 26px;
    height: 26px;
    background: #e9f4ff0d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

export const MenuListStyled = styled.div`
  position: relative;
  border: 1px solid #e9f4ff0d;
  border-radius: 8px;
  font-family: var(--font-family-golos), sans-serif;
  background-color: #1d2234;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .spinner {
    padding: 10px 0;
  }
`;

export const MenuItemBtn = styled.button`
  color: #d2eaffbf;
  font-family: var(--font-family-golos), sans-serif;
  font-style: italic;
  border: none;
  background: #1d2234;
  font-size: 16px;
  text-transform: uppercase;

  cursor: pointer;

  &[role="menuitemradio"] {
    padding: 12px 16px;
  }

  &:hover,
  &[aria-checked="true"] {
    background: #23a3ff0d;
  }
`;

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  max-width: 543px;

  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${({ styles }) => styles}
`;

export const ManagePrizesButton = styled.div<IStyles>`
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  ${({ styles }) => styles}
`;

// hack to have a nice render for verifier modal
export const LeaderboardCreateLayout: React.FC<ILeaderboardCreateProps> = (props) => {
  return (
    <VerifierProvider>
      <LeaderboardCreate {...props} />
    </VerifierProvider>
  );
};
