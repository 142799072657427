import styled from "styled-components";
import Image from "next/image";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const GreatingHeader = styled.h3`
  font-family: Golos Text;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  color: #abbadb;
  margin: 24px 0 0 0;
`;

export const GreatingName = styled.span`
  color: #00b2ff;
`;

export const MainInfoBlock = styled.div`
  gap: 16px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  background: #141722;
  border-radius: 12px;
  overflow: hidden;
`;

export const UserBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: auto;
`;

const Block = styled.div`
  flex: 1;
  background: #141722;
`;

export const ProfileBlock = styled(Block)`
  display: flex;
  gap: 24px;
  align-items: center;
  min-width: max-content;
  @media (min-width: 700px) {
    padding-right: 16px;
    border-right: solid 1.5px var(--dark-space);
  }
`;

export const ProfileImage = styled(Image)`
  border: 4px solid #1d2234;
  border-radius: 50%;
`;

export const ProfileBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ProfileName = styled.h1`
  font-family: Golos Text;
  font-size: 20px;
  font-weight: 500;
  color: #d5e0f5;
  margin: 0px;
  padding: 8px 0;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  gap: 8px;
`;

export const InfoLabel = styled.h6`
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  margin: 0;
  color: #abbadb80;
`;

export const InfoValue = styled.div`
  color: #abbadb;
  width: 100%;
  & :nth-child(1) {
    flex-grow: 1;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 700px) {
    & :nth-child(1) {
      max-width: 200px;
    }
  }
`;

export const ID = styled.div`
  color: #00b2ff;
`;

export const CopyWrapper = styled.div`
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.5;
  }
`;

export const SocialBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  @media (max-width: 700px) {
    order: 2;
    border-top: solid 1.5px var(--dark-space);
    padding-top: 16px;
  }
`;

export const SocialIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  background: var(--rainy-morning);
  border-radius: 8px;
`;

export const BalanceBlock = styled.div`
  padding: 24px;
  background: var(--rainy-morning);
  border-radius: 8px;
  flex-grow: 1;
  @media (max-width: 500px) {
    padding: 16px;
  }
`;

export const BalanceTitle = styled.h5`
  font-family: Golos Text;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  color: #c8d7f580;
  margin: 0;
  margin-bottom: 8px;
`;

export const Balances = styled.div`
  display: flex;
  gap: 4px;
`;

export const Balance = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 8px;
  background: #141722;
  border-radius: 6px;
  box-sizing: border-box;

  .info-icon {
    color: #abbadb;
  }
`;

export const PointBalance = styled.div`
  display: flex;
  gap: 8px;
`;

export const PointInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PointName = styled.h6`
  text-transform: uppercase;
  text-wrap: nowrap;
  color: #abbadb80;
  font-family: Golos Text;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  margin: 0;
`;

export const PointValue = styled.div`
  color: #abbadb;
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
