import {
  ProfileTab,
  ProfileTabBlock,
  ProfileTabDescription,
  ProfileTabHeader,
  ProfileTabTitle,
} from "../styles";
import {
  Root,
  GamdomBlock,
  DiscordBlock,
  KickBlock,
  BlockDescript,
  SocialButton,
  DiscordButton,
  GamdomBlockInfo,
  UnlinkButton,
  ButtonDescription,
  Section,
} from "./styles";
import Image from "next/image";
import TextInput from "../components/TextInput";
import GamdomGuideModal from "components/public/ProfileModalTabs/guides/GamdomGuideModal";
import { useState } from "react";
import { useProfileContext } from "components/public/ProfileLayout/ProfileContext";
import { useConnectionsTab } from "./useConnectionsTab";
import Loader from "components/common/Loader";
import KickGuideModal from "components/public/ProfileModalTabs/guides/KickGuideModal";
import QRСodeModal from "components/ScreenComponent/MFAModals/QRСodeModal";
import { IAppContext, withApp } from "contexts";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";
import KickSvg from "/public/images/icons/kick.svg";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import CopyButton from "components/wrewards-ui-kit/Button/CopyButton";

export type ConnectionsTabProps = IAppContext & {
  isCanEdit?: boolean;
};

const ConnectionsTab: React.FC<ConnectionsTabProps> = ({
  showQrModal,
  setShowQrModal,
  profile: user,
  isCanEdit,
  onDiscordRedirect,
}) => {
  const { profile, updateUser, isOwnProfile, fetchUser, isAdminCanEdit } = useProfileContext();
  const {
    isLoading,
    unlinkPlatform,
    unlinkDiscord,
    verifyDiscord,
    setPlatform,
    kickUsername,
    discordUsername,
    platform,
    isPlatformCanConnect,
    isDiscordLinked,
    hasVerifiedRoleOnDiscordGuild,
    isVerifyLoading,
  } = useConnectionsTab(profile!);
  const [showGamdomGuideModal, setShowGamdomGuideModal] = useState<boolean>(false);
  const [showKickGuideModal, setShowKickGuideModal] = useState<boolean>(false);
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);

  const isMfaEnabled = !!user?.mfaSecret;

  return profile && !isLoading ? (
    <>
      <ProfileTab>
        <ProfileTabBlock>
          {/* <ProfileTabHeader> */}
          {/* <ProfileTabTitle>Connections</ProfileTabTitle> */}
          {/*  */}
          {/* <ProfileTabDescription>
              Some text bout user stats and how it works and why
            </ProfileTabDescription> */}
          {/* </ProfileTabHeader> */}
          <Root>
            <GamdomBlock>
              <GamdomBlockInfo>
                <Image
                  src={"/images/icons/gamdom-with-text.png"}
                  alt="gamdom"
                  width={168}
                  height={40}
                />
                <BlockDescript>Link you casino account for the best experience</BlockDescript>
              </GamdomBlockInfo>
              <GamdomBlockInfo>
                <TextInput
                  disabled={!isOwnProfile}
                  inputProps={{
                    type: "number",
                  }}
                  text={profile.gamdomId === 0 ? "" : profile.gamdomId?.toString() ?? ""}
                  placeholder="Example: ID202020"
                  saveNewValue={(value) =>
                    updateUser({
                      field: "gamdomId",
                      value: Number(value),
                    })
                  }
                />
                <SocialButton
                  onClick={() => {
                    setShowGamdomGuideModal(true);
                  }}
                >
                  How to find my ID?
                </SocialButton>
              </GamdomBlockInfo>
            </GamdomBlock>
            <Section>
              <DiscordBlock>
                <Image src={"/images/icons/discord.svg"} alt="gamdom" width={56} height={56} />
                <BlockDescript>Link you discord account for the best experience</BlockDescript>
                <WrewardsButton
                  style={{ height: 50 }}
                  variant="discord-action"
                  onClick={async () => {
                    if (!isOwnProfile || isDiscordLinked) return;
                    await onDiscordRedirect();
                  }}
                >
                  {isDiscordLinked ? (
                    <>
                      {discordUsername}
                      <CopyButton size="sm" color="#abbadb" copyValue={discordUsername ?? ""} />
                    </>
                  ) : isOwnProfile ? (
                    "Add Discord"
                  ) : (
                    "Not linked"
                  )}
                </WrewardsButton>

                {isDiscordLinked && isAdminCanEdit && (
                  <UnlinkButton
                    onClick={async () => {
                      await unlinkDiscord();
                      await fetchUser();
                    }}
                  >
                    unlink
                  </UnlinkButton>
                )}
                {isDiscordLinked && (
                  <>
                    <ButtonDescription>
                      Server joined and verified
                      <Image
                        src={
                          hasVerifiedRoleOnDiscordGuild
                            ? "/images/icons/check.png"
                            : "/images/icons/x-mark.png"
                        }
                        width={12}
                        height={12}
                        alt="discord_server_status"
                      />
                    </ButtonDescription>
                    {!hasVerifiedRoleOnDiscordGuild && (
                      <UnlinkButton
                        disabled={!isOwnProfile}
                        onClick={async () => {
                          await verifyDiscord();
                          await fetchUser();
                        }}
                      >
                        {isVerifyLoading ? <Loader /> : "Recheck"}
                      </UnlinkButton>
                    )}
                  </>
                )}
              </DiscordBlock>
              <KickBlock>
                <KickSvg
                  style={{ color: "#35f224 " }}
                  // src="/images/icons/kick.png"
                  width={56}
                  height={56}
                />
                {/* <Image src={"/images/icons/kick.svg"} alt="gamdom" width={56} height={56} /> */}
                <BlockDescript>Link you kick account for the best experience</BlockDescript>
                <WrewardsButton
                  style={{ height: 50 }}
                  variant="kick-action-green"
                  onClick={async () => {
                    if (!isOwnProfile || !!kickUsername) return;
                    if (!kickUsername) {
                      const isCanConnect = await isPlatformCanConnect("kick");
                      if (isCanConnect) {
                        if (isCanEdit) {
                          setShowKickGuideModal(true);
                        }
                      }
                    }
                  }}
                >
                  {!!kickUsername ? (
                    <>
                      {kickUsername}
                      <CopyButton size="sm" color="#abbadb" copyValue={discordUsername ?? ""} />
                    </>
                  ) : isOwnProfile ? (
                    "Add Kick"
                  ) : (
                    "Not linked"
                  )}
                </WrewardsButton>
                {kickUsername && isAdminCanEdit && (
                  <UnlinkButton
                    onClick={(e) => {
                      e.preventDefault();
                      setPlatform("kick");
                      if (isMfaEnabled) {
                        setShowVerifyModal(true);
                      } else {
                        setShowQrModal(true);
                      }
                    }}
                  >
                    unlink
                  </UnlinkButton>
                )}
              </KickBlock>
            </Section>
          </Root>
        </ProfileTabBlock>
      </ProfileTab>

      {showGamdomGuideModal && <GamdomGuideModal onClose={() => setShowGamdomGuideModal(false)} />}
      {showKickGuideModal && <KickGuideModal onClose={() => setShowKickGuideModal(false)} />}
      {showQrModal && <QRСodeModal onClose={() => setShowQrModal(false)} />}

      {isMfaEnabled && showVerifyModal && (
        <MFAModal
          onVerify={async (otpObject) => {
            await unlinkPlatform({ platform: platform!, otp: otpObject.otp });
            await fetchUser();
          }}
          onClose={() => {
            setShowVerifyModal(false);
            setPlatform(null);
          }}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default withApp(ConnectionsTab);
