import { getGradientPresets } from "components/games/SlotGames/gradientsPresets";
import Image from "next/image";
import Link from "next/link";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { useSlotActions } from "hooks/games/slots/useSlotActions";
import { AddFavoriteSlotAction, AddFavoriteSlotDto, FindSlotGamesResponse } from "types/slots/dto";
import { SlotProviders } from "types/slots/constants";
import Info from "/public/images/icons/info.svg";
import Like from "/public/images/icons/like.svg";
import Play from "/public/images/icons/play-2.svg";
import { useAppContext } from "contexts";

interface SlotGameCardNewProps {
  name: string;
  provider: string;
  code: string;
  codeSlug: string;
  className?: string;
  imageCode: string;
  isFavorite?: boolean;
  refetchAction?: any;
}

const SlotGameCardNew: React.FC<SlotGameCardNewProps> = ({
  name,
  provider,
  code,
  codeSlug,
  className,
  imageCode,
  isFavorite,
  refetchAction,
}) => {
  const { onAddFavoriteSlot } = useSlotActions();
  const { profile } = useAppContext();

  const router = useRouter();
  const [showFallback, setFallback] = useState(false);
  const preset = useMemo(() => getGradientPresets(imageCode), [imageCode]);
  const sourceUrl = "https://wrewards.s3.eu-north-1.amazonaws.com/slots_nw";
  const [isFavoriteSlot, setIsFavoriteSlot] = useState(isFavorite);

  useEffect(() => {
    setIsFavoriteSlot(isFavorite);
  }, [isFavorite]);

  const onFavoriteActionClick = useCallback(async () => {
    const dto: AddFavoriteSlotDto = { code, provider };
    const action: AddFavoriteSlotAction = isFavoriteSlot ? "remove" : "add";
    const onSuccess = () => setIsFavoriteSlot((prev) => !prev);
    await onAddFavoriteSlot(dto, action, onSuccess);
    if (refetchAction) await refetchAction();
  }, [code, provider, isFavoriteSlot, onAddFavoriteSlot, refetchAction]);

  const {
    bg: bgImage,
    icon: iconImage,
    text: textImage,
  } = {
    bg: `${sourceUrl}/${provider.toLowerCase()}/${imageCode}/bg.png`,
    icon: `${sourceUrl}/${provider.toLowerCase()}/${imageCode}/icon.png`,
    text: `${sourceUrl}/${provider.toLowerCase()}/${imageCode}/text.png`,
  };

  return (
    <SlotCard
      colorPreset={preset}
      className={className}
      onClick={() => {
        router.push(`/games/slots/${codeSlug}`);
      }}
    >
      <div className="hover-actions">
        <div className="top-buttons">
          <IconButton className="iconButton">
            <Info />
          </IconButton>
          <IconButton
            className="iconButton"
            data-active={isFavoriteSlot}
            disabled={null == profile}
            onClick={(e) => {
              e.stopPropagation();
              onFavoriteActionClick();
            }}
          >
            <Like />
          </IconButton>
        </div>
        <div className="center-buttons">
          <Link href={`/games/slots/${code}`} className="iconButton icon-xl">
            <Play />
          </Link>
        </div>
      </div>
      <Image
        fill
        alt={`${name}-bg`}
        className="img-bg"
        src={bgImage}
        loading="eager"
        sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
        quality={30}
        onError={() => setFallback(true)}
        style={
          showFallback
            ? {
                opacity: showFallback ? 0 : 1,
              }
            : {
                opacity: showFallback ? 0 : 1,
              }
        }
      />
      {showFallback ? (
        <>
          <Image
            fill
            alt={`${name}-bg`}
            className="img-bg"
            src={`https://wrewards.s3.eu-north-1.amazonaws.com/mole-smash/mole-smash_bg.png`}
            loading="eager"
            sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
            quality={30}
            style={{
              filter: "blur(4px)",
            }}
          />
          <Image
            fill
            alt={`${name}-icon`}
            className="img-icon"
            style={{ padding: "10px 20px 60px", filter: "blur(2px)" }}
            src={`/images/blackjack/wrewards-back.svg`}
            loading="eager"
            sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
            quality={80}
          />
        </>
      ) : (
        <>
          <Image
            fill
            alt={`${name}-icon`}
            className="img-icon"
            src={iconImage}
            loading="eager"
            sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
            quality={80}
          />
          <Image
            fill
            alt={`${name}-name`}
            className="img-name"
            src={textImage}
            loading="eager"
            sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
            quality={20}
          />
        </>
      )}

      <Link href={`/games/slots/${codeSlug}`} className="content">
        {showFallback && (
          <>
            <span className="name">{name}</span>
            {/* @ts-ignore */}
            <span className="provider">{SlotProviders?.[provider] ?? provider}</span>
          </>
        )}
      </Link>
    </SlotCard>
  );
};

export default SlotGameCardNew;

export const SlotCard = styled.div<{ colorPreset: string[] }>`
  user-select: none;

  transition: transform 0.1s ease-in-out;
  aspect-ratio: 3/4;
  box-shadow: 0px 0px 0px 1px #1e213a;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px;
    z-index: 1;
    pointer-events: none;
  }

  .name {
    color: var(--daylight-daylight-main, #d5e0f5);
    z-index: 2;
    font-family: var(--font-family-golos);
    font-weight: 900;
    text-align: center;
    font-size: 16px;
    text-shadow: 0px 1px 2px #000000b0;
  }

  .provider {
    color: var(--daylight-daylight-main, #d5e0f5);
    font-family: var(--font-family-golos);
    font-size: 15px;
    font-weight: 500;
    text-shadow: 0px 1px 2px #000000b0;
  }

  &::after {
    position: absolute;
    inset: 0 -10px -20px;
    content: "";

    background: linear-gradient(
      181.56deg,
      rgba(7, 244, 187, 0) 36.21%,
      ${({ colorPreset }) => colorPreset?.[0]} 53.81%,
      ${({ colorPreset }) => colorPreset?.[1]} 78.74%
    );
    transition: all linear 0.2s;
  }

  .img-name {
    z-index: 2;
    transition: transform 0.2s ease-in-out;
  }
  .img-name {
    transform: translateY(-10px);
    filter: drop-shadow(0px 1px 2px #000000b0);
  }
  .img-icon,
  .img-bg {
    transition: transform 0.3s linear;
    transform: scale(1.01);
    box-shadow: 0px 0px 0px 2px #1e213a inset;
  }

  .name {
    transition: transform 0.2s linear;
  }

  .provider {
    opacity: 0;
    transition: transform 0.2s linear;
  }

  &:hover {
    cursor: pointer;

    .img-name {
      pointer-events: none;
      z-index: 3;
      transform: scale(1.01) translateY(-16px);
    }

    .provider {
      opacity: 1;
      /* transform: translateY(0); */
      transform: translateY(-20px);
    }

    .name {
      /* transform: translateY(0); */
      transform: translateY(-25px);
    }

    .hover-actions {
      opacity: 1;
    }

    .icon-xl {
      svg {
        color: #00b2ff;
      }
    }
  }

  .hover-actions {
    opacity: 0;
    position: absolute;
    inset: 0;
    z-index: 3;
    background: rgba(20, 23, 34, 0.5);
    backdrop-filter: blur(6px);
    transition: all 0.3s ease-in-out;
  }

  .top-buttons {
    z-index: 3;
    padding: 12px;
    display: flex;
    justify-content: space-between;
  }

  .center-buttons {
    padding: 0 12px;
    display: flex;
    justify-content: center;
  }
`;

const IconButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: rgba(171, 186, 219, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: 0.3s all ease-in-out;
  color: rgb(171, 186, 219);

  &.icon-xl {
    width: 48px;
    height: 48px;
    border-radius: 48px;

    &:active {
      transform: scale(1.1);
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    color: rgb(171, 186, 219);
  }
  &[data-active="true"],
  &:not(:disabled):hover {
    color: #00b2ff;
  }
`;
