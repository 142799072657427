import { Button, ButtonBlue, Flex } from "components";
import Image from "next/image";
import styled from "styled-components";
import { useRouter } from "next/router";
import React from "react";
import Countdown, { zeroPad } from "react-countdown";

const TitleText = styled.h1`
  color: rgba(210, 234, 255, 1);
  font-family: var(--font-family-golos)
  font-size: 18px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;
  text-shadow: 0px 2px 4px rgba(11, 14, 35, 1), 0px 0px 6px rgba(58, 113, 254, 0.46),
    0px 0px 24px rgba(40, 113, 255, 0.5);
`;

export interface EndedRaffleData {
  deadline: string;
  id: number;
}

const Timer = ({ data }: { data: EndedRaffleData }) => {
  const givenDate = data.deadline;
  const router = useRouter();
  const id = data.id;

  const renderer = ({
    hours,
    minutes,
    seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    return (
      <p style={{ whiteSpace: "nowrap" }}>
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </p>
    );
  };

  return (
    <Flex column>
      <Flex align="center">
        <Image
          style={{ borderRadius: "50%", marginRight: 10 }}
          src="/images/PointShopComponent/tickets.png"
          height={30}
          width={30}
          alt="image"
        />
        <TitleText>Raffle #{data.id}</TitleText>
      </Flex>

      <Flex color="#a2b5c8" column>
        <Flex>
          <p>Raffle #{data.id} ends in</p>
          <span style={{ marginRight: "5px" }}></span>
          <Countdown date={givenDate} renderer={renderer} />
        </Flex>
        <br />
        Click to see who wins!
      </Flex>
      <Button
        onClick={() => router.push(`/raffles/${id}`)}
        isDark
        style={{ margin: "10px auto", width: "60%", whiteSpace: "nowrap" }}
      >
        <ButtonBlue isDark>Go to the Raffle</ButtonBlue>
      </Button>
    </Flex>
  );
};

export default Timer;
