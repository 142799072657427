import { Button, ButtonBlue, Flex } from "components";
import styled from "styled-components";
import { useRouter } from "next/router";
import React from "react";

const TitleText = styled.div`
  color: rgba(210, 234, 255, 1);
  font-family: var(--font-family-golos)
  font-size: 18px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;
  text-shadow: 0px 2px 4px rgba(11, 14, 35, 1), 0px 0px 6px rgba(58, 113, 254, 0.46),
    0px 0px 24px rgba(40, 113, 255, 0.5);
`;

export interface RaffleDataProps {
  title: string;
  id: number;
}

const RaffleRouletteSnackbar = ({ raffle }: { raffle: RaffleDataProps }) => {
  const router = useRouter();

  const raffleId = raffle.id;

  return (
    <Flex column>
      <Flex style={{ marginBottom: 15 }} column>
        <TitleText>Raffle #{raffleId}</TitleText>
        <TitleText>Roulette Starts!</TitleText>
      </Flex>

      <Flex color="#a2b5c8" column>
        Click to see who wins!
      </Flex>
      <Button
        onClick={() => router.push(`/raffles/${raffleId}`)}
        isDark
        style={{ margin: "10px auto", width: "60%", whiteSpace: "nowrap" }}
      >
        <ButtonBlue isDark>Go to the Raffle</ButtonBlue>
      </Button>
    </Flex>
  );
};

export default RaffleRouletteSnackbar;
