import React from "react";
import SlideButtonGroup from "./SlideButtonGroup";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import Image from "next/image";
import { TheLeaderboardSourceKeys } from "interfaces";
import { CSSProperties } from "styled-components";

const defaultLeaderboardOptions = [
  {
    key: TheLeaderboardSourceKeys.gamdom,
    src: "/images/AllBonuses/provider-logos/gamdom-32.png",
    width: 105,
    height: 52,
  },
  {
    key: TheLeaderboardSourceKeys.packdraw,
    src: "/images/AllBonuses/provider-logos/packdraw-32.png",
    width: 131,
    height: 60,
  },

  {
    key: TheLeaderboardSourceKeys.clash,
    src: "/images/AllBonuses/provider-logos/clash-32.png",
    width: 120,
    height: 36,
    style: {
      position: "relative",
      top: 2,
    },
  },
  {
    key: TheLeaderboardSourceKeys.cases,
    src: "/images/AllBonuses/provider-logos/cases-32.png",
    width: 100,
    height: 40,
  },
  {
    key: TheLeaderboardSourceKeys.rain_gg,
    src: "/images/AllBonuses/provider-logos/rain.png",
    width: 100,
    height: 30,
    style: {
      position: "relative",
      top: -3,
    },
  },
  {
    key: TheLeaderboardSourceKeys.wrewards,
    src: "/images/AllBonuses/provider-logos/wrewards-32.png",
    width: 121,
    height: 52,
  },
];

type Props = {
  value: string;
  onChange: (v: string) => void;
};

function LeaderboardProviderSwitcher({ value, onChange }: Props) {
  return (
    <SlideButtonGroup>
      {defaultLeaderboardOptions.map(({ key, src, style }) => {
        return (
          <WrewardsButton
            style={{ minWidth: 150 }}
            className={key === value ? "active" : ""}
            variant="ghost-neon"
            key={key}
            onClick={() => onChange(key)}
          >
            <div className="img" style={style as CSSProperties}>
              <Image fill src={src} alt="" style={{ objectFit: "contain" }} />
            </div>
          </WrewardsButton>
        );
      })}
    </SlideButtonGroup>
  );
}

export default LeaderboardProviderSwitcher;
